import React from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

// The following registers the plugin with `videojs`
require('@silvermine/videojs-quality-selector')(videojs)

import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css'
// video.js player from the docs: https://github.com/videojs/video.js/blob/master/docs/guides/react.md

const playerOptions = {
  fill: true,
  fluid: true,
  preload: 'auto',
  controls: true,
  responsive: true,
  autoplay: true,
  aspectRatio: '16:9',
  playbackRates: [0.50, 0.75, 1, 1.25, 1.5, 2],
  html5: {
    hls: {
      enableLowInitialPlaylist: true,
      smoothQualityChange: true,
      overrideNative: true,
    },
    nativeVideoTracks: false,
    nativeAudioTracks: false,
    nativeTextTracks: false,
  },
}

class VideoPlayer extends React.Component {

  componentDidMount() {
    const video = this.props.lection.content
    const status = this.props.lection.content.status
    const urlParams = new URLSearchParams(window.location.search)
    const time = urlParams.get('t') || 0
    const videoStartTime = this.props.videoStartTime || time

    // videojs.Hls.xhr.beforeRequest = function (options) {
    //   options.headers = {
    //     'Authorization': process.env.MEDIA_SERVER_API_KEY,
    //     'Access-Control-Allow-Origin': '*'
    //   };

    //   return options;
    // }

    this.player = videojs(this.videoNode, playerOptions)
    this.player.controlBar.addChild('QualitySelector')
    console.log(video.file_type, ':', video.url)
    console.log(video.hls_sd_url, ':', video.hls_hd_url, ':', video.hls_url)
    if (process.env.NODE_ENV === 'development') {
      this.player.src([{ type: video.file_type, src: video.url, label: 'hd' }])
    } else {
      if (video.multiformat) {
        this.player.src([{
          type: 'application/vnd.apple.mpegurl',
          src: video.hls_sd_url,
          label: 'sd'
        },
        {
          type: 'application/vnd.apple.mpegurl',
          src: video.hls_hd_url,
          label: 'hd',
          selected: "true",
        }])
      } else {
        this.player.src([{
          type: 'application/vnd.apple.mpegurl',
          src: video.hls_url,
          label: 'hd'
        }])
      }
    }

    //this.player.on('ended', () => {
    //  this.props.markLectionCompleted(this.props.lection)
    //})

    if (videoStartTime > 0) {
      this.player.currentTime(videoStartTime)
    }

    this.intervalID = setInterval(this.checkPlayerProgress.bind(this), 3000)
  }

  checkPlayerProgress() {
    let percentComplete = this.player.currentTime() / this.player.duration() * 100
    if (percentComplete >= 90) {
      this.props.markLectionCompleted(this.props.lection)
    }
  }

  getCurrentTime() {
    return this.player.currentTime()
  }

  setCurrentTime(time) {
    this.player.currentTime(time)
  }

  pause() {
    this.player.pause()
  }

  play() {
    this.player.play()
  }

  componentWillUnmount() {
    if (this.intervalID) {
      clearInterval(this.intervalID)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.player && prevProps.lection.id !== this.props.lection.id) {
      const video = this.props.lection.content
      const videoStartTime = this.props.videoStartTime || 0
      if (process.env.NODE_ENV === 'development') {

        this.player.src([{ type: video.file_type, src: video.url, label: 'hd' }])
      } else {
        if (video.multiformat) {
          this.player.src([{
            type: 'application/vnd.apple.mpegurl',
            src: video.hls_sd_url,
            label: 'sd'
          },
          {
            type: 'application/vnd.apple.mpegurl',
            src: video.hls_hd_url,
            label: 'hd',
            selected: "true",
          }])
        } else {
          this.player.src([{
            type: 'application/vnd.apple.mpegurl',
            src: video.hls_url,
            label: 'hd'
          }])
        }
      }

      if (videoStartTime > 0) {
        this.player.currentTime(videoStartTime)
      }
    }

    if (this.player &&
      prevProps.lection.id === this.props.lection.id &&
      prevProps.videoStartTime !== this.props.videoStartTime) {

      const videoStartTime = this.props.videoStartTime || 0

      if (videoStartTime > 0) {
        this.player.currentTime(videoStartTime)
      }
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  // use `ref` to give Video JS a reference to the video DOM element: https://reactjs.org/docs/refs-and-the-dom
  render() {
    return (
      <div data-vjs-player>
        <video ref={node => this.videoNode = node} className="video-js vjs-default-skin vjs-big-play-centered video_desktop"
          controls style={{ width: '100%', height: '100%' }}></video>
      </div>
    )
  }
}

export default VideoPlayer