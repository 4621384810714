import React from 'react'
import { client } from '../Client'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'

class Price extends React.Component {

  render() {
    const course = this.props.course
    const locale = window.locale || 'ru'
    const maxCoursePrice = course.author.payment_provider === 'tinkoff' ? 30000 : 1000000
    var discountStatus = parseInt(course.discount_price) > 0

    const validationSchema = Yup.object().shape({
      price: Yup.number()
        .required(window.t('form.required'))
        .min(-1)
        .max(maxCoursePrice, window.t('form.must_be_less_or_equal_to') + ' ' + maxCoursePrice)
        .integer(),
      discount_price: Yup.number()
        .min(0)
        .integer()
        .max(Yup.ref('price'), window.t('course.less_then_price'))
    })

    function toggleDiscountField() {
      document.querySelector("input[name='discount_price']").toggleAttribute('hidden')
      document.querySelector(".discount-hint").toggleAttribute('hidden')
      discountStatus = !discountStatus
    }


    return (

      <div className="" id="price">
        <Formik
          initialValues={{
            price: course.price,
            discount_price: course.discount_price
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {

            if (values.discount_price == '' || values.discount_price == null) { discountStatus = false }
            if (!discountStatus) document.getElementById("enable_discount").checked = false
            let data = {}
            data['course_price_attributes'] = values

            client.updateCourse(course.id, data, discountStatus)
              .then((course) => {
                if (course.id) {
                  toastrNotification({ type: 'success', title: window.t('updated') })
                  this.props.onUpdateCourse(course)
                } else {
                  toastrNotification({ type: 'error', title: window.t('not_updated') })
                }
                setSubmitting(false)
              })
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label className="form-label">{window.t('course.price')}</label>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    name="price"
                    className={"form-control " + (errors.price && touched.price ? 'is-invalid' : '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    placeholder={window.t('course.price_placeholder')}
                    disabled={!course.ready_for_publication}
                  />
                  {errors.price && touched.price &&
                    <div className="invalid-feedback">{errors.price}</div>
                  }
                </div>
                <div className="col-md-4 col-sm-12 mt-3 mt-md-0">
                  <label className="form-label me-3">{window.t('course.discount_price')}</label>
                  <input type="checkbox" name="enable_discount"
                    onChange={toggleDiscountField}
                    id="enable_discount"
                    defaultChecked={discountStatus} />
                  <input
                    type="number"
                    min="0"
                    step="1"
                    name="discount_price"
                    hidden={!discountStatus}
                    className={"form-control " + (errors.discount_price && touched.discount_price ? 'is-invalid' : '')}
                    onChange={handleChange}

                    onBlur={handleBlur}
                    value={values.discount_price}
                    placeholder={window.t('course.discount_price_placeholder')}
                    disabled={!course.ready_for_publication}
                  />
                  {errors.discount_price && touched.discount_price &&
                    <div className="invalid-feedback">{errors.discount_price}</div>
                  }
                  <div className='discount-hint'
                    hidden={!discountStatus}
                  >
                    {values.discount_price === 0 &&
                      <small>{window.t('course.discount_price_hint')}</small>
                    }
                    {values.discount_price > 0 && values.price > 0 &&
                      <small>{window.t('course.has_discount')} <span className="text-danger">{Math.round((values.price - values.discount_price) / values.price * 100)}%</span></small>
                    }
                  </div>
                </div>
                {/* {course.ready_for_publication && */}

                <div className="col-sm-12 col-md-4 mt-md-4">
                  <button type="submit" className="btn btn-primary mt-1" disabled={isSubmitting || !isValid}>
                    {window.t('save')}
                  </button>
                </div>
                {/* } */}
              </div>




            </form>
          )}
        </Formik>
        {!course.ready_for_publication &&
          <div className="row">
            <div className="col-12">
              <a href={Routes.tutor_bank_cards_path(locale)}>{window.t('course.contract_is_needed')}</a>

              {/* {course.author.payment_provider === 'mkb'
                ? window.t('course.contract_is_needed')
                : <div>
                  {window.t('course.add_bank_card_warning')} <br />
                  <a href={Routes.tutor_bank_cards_path(locale)}>{window.t('course.add_bank_card')}</a>
                </div>
              } */}
            </div>

          </div>
        }
        <div className="mt-2">
          {course.author.subscription && course.author.subscription.active ?
            <div className='row mb-3' >
              <div className='col-md-7 col-sm-12'>
                {/* <small>{window.t('course.subscriptions_description')} </small> */}
                <small className='mt-1 mb-0'>{window.t('course.subscription.current_subscription')}: "{course.author.subscription.name}"<br /></small>
                <small className='mb-0'>{window.t('course.subscription.duration')}{course.author.subscription.period_months} {window.t('course.subscription.months')}<br /></small>
                {/* Цена: {course.author.subscription.price} */}
                {course.author.subscription.one_course ?
                  <small className='mb-0'>{window.t('course.subscription.used')} {course.author.subscription.licenses_distributed} {window.t('course.subscription.licenses')}</small>
                  :
                  <small>{window.t('course.subscription.used')} {course.author.subscription.licenses_distributed} {window.t('course.subscription.from')} {course.author.subscription.licenses_limit} {window.t('course.subscription.licenses')}</small>
                }
              </div>
              <div className='col-md-5 col-sm-12'>
                <a className="btn btn-primary m-1" href={Routes.tutor_subscription_path()}>
                  {window.t('course.subscription.update')}
                </a>
              </div>
            </div>
            :
            <div className='col-12 '>
              <div className="row mt-3">
                <div className="col-md-8 col-sm-12 mb-2">
                  <small>
                    {window.t('course.subscription.description_for_all_course')}
                  </small>
                </div>
                <div className="col-md-4 col-sm-12">
                  <a className="btn btn-primary m-1" href={Routes.new_subscription_path()}>
                    {window.t('course.all_courses_subscription')}
                  </a>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

    )
  }
}

export default Price