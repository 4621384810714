import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const AudioContent = ({ url }) => {
  return (
    <div className="player-audio" style={{ backgroundColor: "white" }}>
      <div className="audio-player-container m-4">
        <AudioPlayer
          autoPlay={true}
          src={url}
          onPlay={e => console.log("Audio started playing")}
          showJumpControls={true}
          showFilledVolume={true}
          customControlsSection={[
            'MAIN_CONTROLS',
            'VOLUME_CONTROLS',
          ]}
        />
      </div>
    </div>
  );
};

export default AudioContent;