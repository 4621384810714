import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  updatePrices() {
    this.element.requestSubmit()
  }

  changePeriod(event) {
    let periodLink = event.target.dataset.periodLink
    window.location.href = periodLink;
  }
}