import React, { Component } from 'react'
import { Modal, Button, OverlayTrigger } from 'react-bootstrap'
import Cropper from 'react-cropper'
import { client } from '../Client'
import 'cropperjs/dist/cropper.css'

class ImageCropper extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      canSubmit: false,
      src: '',
      cropResult: null,
    }

    this.cropRef = React.createRef()
  }

  onChange = (e) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      this.setState({ src: reader.result })
    }
    reader.readAsDataURL(files[0])
  }

  onCrop = () => {
    const cropper = this.cropRef.current.cropper

    if (this.cropper === null) {
      this.setState({
        canSubmit: false,
        cropResult: null
      })
    }

    if (cropper && typeof (cropper.getCroppedCanvas() != 'undefined')) {
      this.setState({
        cropResult: cropper.getCroppedCanvas({ width: 1280, height: 920 }).toDataURL(),
        canSubmit: true
      })
    }
  }

  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true, cropResult: null });
  }

  updateImage = (e) => {
    e.preventDefault()
    this.setState({ canSubmit: false })
    const cropped_photo = this.state.cropResult
    this.props.onUpdateImage(this.state.cropResult)
    this.close()
  }

  render() {

    return (
      <div>
        <button
          className="btn btn-primary btn-xs"
          type="button"
          onClick={this.open}
        >
          {window.t('course.upload_image')}
        </button>
        <Modal show={this.state.showModal} onHide={this.close} size='lg'>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 text-center">
                <div style={{ marginBottom: "10px" }}>
                  <span className="mg-r-10">{window.t('course.select_file')}</span>
                  <input type="file" onChange={this.onChange} />
                </div>
              </div>
              <div className="col-md-12 text-center">
                <Cropper
                  style={{ height: 250, width: '100%' }}
                  initialAspectRatio={324 / 217}
                  aspectRatio={324 / 217}
                  preview=".img-preview"
                  guides={false}
                  crop={this.onCrop}
                  src={this.state.src}
                  ref={this.cropRef}
                />
              </div>
            </div>
            {this.state.src.length > 0 &&
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>{window.t('course.preview_image')}</h1>
                  <div className="img-preview" style={{ height: 200, marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
              </div>
            }
          </Modal.Body>

          <Modal.Footer className="justify-content-between">
            <a className="btn btn-link" onClick={this.close} href="#">Отмена</a>
            <Button
              bsclass="btn btn-primary ml-auto"
              type="submit"
              disabled={!this.state.canSubmit}
              onClick={this.updateImage}
            >
              {window.t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ImageCropper
