import React, { useState, useEffect } from 'react'
import textFragmentImg from '../../images/mobile/text-fragment.png'
import surveyImg from '../../images/mobile/survey.png'
import stopBlockImg from '../../images/desktop/stop-block.svg'
import documentImg from '../../images/mobile/document.png'
import meetingImg from '../../images/desktop/meeting.svg'
import recordingImg from '../../images/mobile/recording.svg'
import audioImg from '../../images/mobile/audio.svg'




const lectionIcon = (lection, currentLection, trackers, finishCourseMessageIsActive, course) => {
  if (lection.content.is_video) {
    return (course.image_url)
  } else if (lection.content.is_survey) {
    return (surveyImg)
  } else if (lection.content.file_type == 'application/pdf') {
    return (documentImg)
  } else if (lection.content.file_type && lection.content.file_type.includes('image/')) {
    return (documentImg)
  } else if (lection.content.is_stop_block) {
    return (stopBlockImg)
  } else if (lection.content.is_meeting) {
    return (meetingImg)
  } else if (lection.content.is_recording) {
    return (recordingImg)
  } else if (lection.content.is_audio) {
    return (audioImg)
  } else {
    return (textFragmentImg)
  }
}

const lectionLinkClass = (lection, currentLection, trackers, finishCourseMessageIsActive) => {
  let baseClass = "btn btn-round btn-sm mb-0 stretched-link position-static "
  let colorClass

  if (currentLection.id === lection.id && !finishCourseMessageIsActive) {
    colorClass = "btn-primary-soft"
  } else if (trackers.filter(tracker => tracker.completed_at !== null).findIndex(tracker => tracker.lection_id == lection.id) !== -1) {
    colorClass = "btn-success-soft"
  } else {
    colorClass = "btn-danger-soft"
  }

  return baseClass.concat(colorClass)
}

const lectionNumber = (lections, currentLection) => {
  const lectionIndex = lections.findIndex(lection => lection.id == currentLection.id)
  return lectionIndex === -1 ? '' : lectionIndex + 1
}

const CourseSection = (props) => {
  const [open, setOpen] = useState(props.isOpened)
  const { section, course, locale, currentLection, loadLection, index,
    trackers, notes, showFinishCourseLink, showFinishCourseMessage,
    finishCourseMessageIsActive, deleteNote, setPlayerCurrentTime } = props

  useEffect(() => {
    if (props.isOpened) {
      setOpen(true)
    }
  }, [props.isOpened]
  )

  const lectionNotes = (lection) => {
    return notes.filter(note => note.lection_id === lection.id)
  }

  function lectionDuration(lection) {
    return lection.content.is_video || lection.content.is_recording ? lection.content.duration : lection.content.start_time
  }

  function findSurveyLection(lections, lection) {
    if (!lection.content.is_stop_block) return null
    if (lection.content.survey_lection_id == null) return null
    else return lections.find(l => l.id === lection.content.survey_lection_id)
  }

  const unfoldToggle = (e) => {
    e.target.closest('.block_title').classList.toggle('rotate')
    e.target.closest('.section').querySelector('.lessons_block').classList.toggle('hide')
  };

  return (
    <div className="section d-flex flex-column">
      <div className="block_title d-flex" onClick={unfoldToggle}>
        {section.title}
        <svg xmlns="http://www.w3.org/2000/svg" id="ss" width="16" height="17" viewBox="0 0 16 17" fill="none" >
          <path d="M13.28 6.46682L8.93337 10.8135C8.42003 11.3268 7.58003 11.3268 7.0667 10.8135L2.72003 6.46682" stroke="#999999" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      {
        <div className="lessons_block d-flex flex-column">
          {section.lections.map((lection, index) => (
            <>
              <div key={lection + index} className={`lesson d-flex flex-column ${currentLection.id == lection.id ? 'active_lesson' : ''} ${lection.visible ? '' : 'hide-block'}`}>
                <div className={`d-flex ${lection.viewable == true ? '' : 'viewing-disabled'}`}>
                  <a href="#" onClick={event => loadLection(event, lection, 0, findSurveyLection(section.lections, lection))}>
                    <img src={lectionIcon(lection, currentLection, trackers, finishCourseMessageIsActive, course)} alt="" />
                  </a>
                  {!lection.content.is_stop_block ?

                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-start">
                        <a href="#" onClick={event => loadLection(event, lection, 0, findSurveyLection(section.lections, lection))} className="title truncate-text">
                          {lection.content.is_meeting && lection.content.completed ?
                            <p>{lection.title}({window.t('course.meeting_to_video_short')})</p>
                            :
                            <p>{lection.title}</p>
                          }
                        </a>
                      </div>
                      <div className="duration d-flex">
                        {lection.content.is_video || (lection.content.is_meeting && !lection.content.completed) || lection.content.is_recording ?
                          <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" >
                              <path d="M14.6667 7.99992C14.6667 11.6799 11.68 14.6666 8.00004 14.6666C4.32004 14.6666 1.33337 11.6799 1.33337 7.99992C1.33337 4.31992 4.32004 1.33325 8.00004 1.33325C11.68 1.33325 14.6667 4.31992 14.6667 7.99992Z" stroke="#061310" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.4733 10.1199L8.40663 8.88659C8.04663 8.67326 7.7533 8.15992 7.7533 7.73992V5.00659" stroke="#061310" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {lectionDuration(lection)}
                          </>
                          :
                          <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" >
                              <path d="M14.6667 7.99992C14.6667 11.6799 11.68 14.6666 8.00004 14.6666C4.32004 14.6666 1.33337 11.6799 1.33337 7.99992C1.33337 4.31992 4.32004 1.33325 8.00004 1.33325C11.68 1.33325 14.6667 4.31992 14.6667 7.99992Z" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.4733 10.1199L8.40663 8.88659C8.04663 8.67326 7.7533 8.15992 7.7533 7.73992V5.00659" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {/* {lection.content.duration} мин */}
                          </>
                        }
                      </div>
                      <svg width="200" height="5" viewBox="0 0 200 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="200" height="5" rx="2.5" fill="#E0EAE8" />
                        <rect y="0.5" width={trackers.filter(tracker => tracker.completed_at !== null).findIndex(tracker => tracker.lection_id == lection.id) != -1 ? '200' : "0"} height="5" rx="2.5" fill={currentLection.id === lection.id ? "#329A80" : "#C5C5C4"} />
                      </svg>
                    </div>
                    :
                    <p className="text-danger stop_block_message">{lection.notification}</p>

                  }
                </div>
              </div>
            </>
          ))}

          {showFinishCourseLink &&
            <div className="section d-flex flex-column">
              <div className="block_title d-flex">
                <p href="#" onClick={showFinishCourseMessage} className="" >
                  {window.t('course_viewer.finish_course')}
                </p>
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default CourseSection
