import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    triggerSearchActive: {
      type: Boolean,
      default: true
    }
  }

  connect() {
    // const radioBtns = this.element.querySelectorAll('input[type="radio"]');
    if (this.triggerSearchActiveValue) {
      // this.element.addEventListener('click', (event) => {
      //   if (event.target.classList.contains('search')) {
      //     // document.body.classList.toggle('search_active')
      //     if (document.querySelector('.chat_gpt')) {
      //       document.querySelector('.chat_gpt').classList.remove('hide')
      //     }
      //   }
      // })

      if (document.querySelector('.search_wrap img')) {
        document.querySelector('.search_wrap img').addEventListener('click', (event) => {
          document.querySelector('.clear-btn').classList.remove('search_wrap-img');
          document.querySelector('.clear-btn').classList.add('search_wrap-img--hide');
          $('.search').val('')
          document.querySelector('.search-form').requestSubmit();
        })
      }
    }

    // this.element.addEventListener('search', (event) => {
    //   if (document.querySelector('.search_form')) {
    //     document.getElementById("search-form").requestSubmit()
    //   }
    // })

    if (document.querySelector('.search')) {
      document.querySelector('.search').addEventListener('input', (event) => {
        if (document.querySelector('.search_wrap-img--hide')) {
          document.querySelector('.clear-btn').classList.add('search_wrap-img');
          document.querySelector('.clear-btn').classList.remove('search_wrap-img--hide');
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          document.querySelector('.search-form').requestSubmit();
        }, 3000);
      });
    }
    // if (radioBtns) {
    //   radioBtns.forEach((radioBtn) => {
    //     radioBtn.addEventListener('click', () => {
    //       this.element.requestSubmit();
    //     });
    //   });
    // }
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      // if (document.querySelector('#search_source_courses') && document.querySelector('#search_source_subtitles')) {
      //   document.querySelector('#search_source_courses').checked = true
      //   document.querySelector('#search_source_subtitles').checked = false
      // }
      this.element.requestSubmit()
      // if (document.querySelector('.chat_gpt')) {
      //   document.querySelector('.chat_gpt').classList.remove('hide')
      // }
      // document.body.classList.remove('search_active')
    }, $('#search').val().length > 0 ? 500 : 0);
    // if ($('.swiper-first').length !== 0) {
    //   let swiper = document.querySelector('.swiper-first')
    //   let slidesPerColumn = swiper ? swiper.dataset.row : '1'
    //   var swiperFirst = new Swiper(".swiper-first", {
    //     slidesPerColumn: slidesPerColumn,
    //     navigation: {
    //       nextEl: ".swiper-first-navigation .swiper_next",
    //       prevEl: ".swiper-first-navigation .swiper_prev",
    //     },
    //     breakpoints: {
    //       500: {
    //         slidesPerView: 'auto',
    //         spaceBetween: 8,
    //       },
    //       5000: {
    //         spaceBetween: 12,
    //         slidesPerView: 'auto',
    //       },
    //     },
    //   })
    // }
  }

  gptSearch(event) {
    event.preventDefault()
    document.querySelector('#search_source_courses').checked = false
    document.querySelector('#search_source_subtitles').checked = true
    document.getElementById("search-form").requestSubmit()
  }


  // fromHistory() {
  //   let searchText = this.element.querySelector('.search_name').innerHTML
  //   document.querySelector('.search_wrap').querySelector('#search').value = searchText
  //   if (this.triggerSearchActiveValue) {
  //     document.body.classList.toggle('search_active')
  //   }
  // }
}
