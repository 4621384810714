import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import Tus from "@uppy/tus"
import Russian from '@uppy/locales/lib/ru_RU'

import '@uppy/image-editor/dist/style.css'

export default class extends Controller {

  static targets = ['document']

  allowUpload(event) {
    $('#upload-link').toggleClass('restrict-upload')

  }

  uploadDocument(event) {
    event.preventDefault();
    const locale = window.locale == "ru" ? Russian : 'en_EN';

    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['application/pdf']
      },
      autoProceed: false,
      locale: locale
    });

    uppy.use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5 * 1024 * 1024 });
    uppy.use(Dashboard, {
      proudlyDisplayPoweredByUppy: false,
      hideCancelButton: true
    });

    uppy.on('upload-success', (file, response) => {
      let fileData = {
        title: file.name,
        file_orig_name: file.name,
        file_url: response.uploadURL,
        file_size: file.size,
        file_type: file.meta.type
      };
      this.createFile(fileData, this.documentTarget);



      // alert('PDF uploaded successfully!');
      uppy.getPlugin('Dashboard').closeModal();
    });

    uppy.getPlugin('Dashboard').openModal();

  }

  createFile(fileData) {
    const parameters = JSON.stringify({ file: fileData });
    const token = document.getElementsByName("csrf-token")[0].content;

    fetch('/api/v1/files.json', {
      body: parameters,
      method: 'POST',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": token
      },
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.documentTarget.value = data["content_id"];
        // this.docNameTarget.innerHTML = fileData.title;
        document.getElementById('upload-form').submit();
      });
  }
}


