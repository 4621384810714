import React from 'react'
import { client } from '../Client'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'
import Price from './Price'
import TrixEditor from '../../helpers/TrixEditor'
import { months } from 'moment/moment'

class Publication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      months: 1,
      currentProductIndex: 0,
      status: props.course.status || 'draft'
    };
  }

  handleStatusChange = (newStatus) => {
    this.setState({ status: newStatus });
  }

  hideForSaleElement = () => {
    const element = document.getElementById('price');
    if (element) {
      element.style.display = 'none';
    }
  }

  showForSaleElement = () => {
    const element = document.getElementById('price');
    if (element) {
      element.style.display = 'block';
    }
  }

  render() {
    const course = this.props.course

    return (
      <div className="container">
        <div className="card bg-transparent border course-creator-content mb-5">
          <div className="card-body">
            <div className="bs-stepper-content">
              <h4>{window.t('course.publication')}</h4>
              <hr />
              <div className="row">
                <div className="col-12 offset-md-3 col-md-6">

                  <div className="bg-light border rounded p-3">
                    <form id="subscription" method="post" action={Routes.subscription_path({ product_id: course.plan_for_one.products[this.state.currentProductIndex].id })} data-turbo="false"></form>

                    <Formik
                      initialValues={{ status: course.status ? course.status.toString() : 'draft', message: course.temporary_block }}
                      onSubmit={(values, { setSubmitting }) => {
                        client.updateCourse(course.id, values)
                          .then((course) => {
                            if (course.id) {
                              toastrNotification({ type: 'success', title: window.t('updated') })
                              this.props.onUpdateCourse(course)
                            } else {
                              toastrNotification({ type: 'error', title: window.t('not_updated') })
                            }
                            setSubmitting(false)
                          })
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <h5>{window.t('course.select_publish_mode')}</h5>
                              <div role="group" aria-labelledby="my-radio-group">

                                <div className="form-check form-switch">
                                  <Field type="radio" name="status" value="draft" className="form-check-input" id="statusDraft"
                                    onClick={() => {
                                      this.hideForSaleElement();
                                      this.handleStatusChange('draft');
                                    }}
                                  />
                                  <label className="form-check-label" htmlFor="statusDraft">{window.t('course.draft')}</label>
                                </div>
                                {course.apple_user ?
                                  <div className="mb-3">
                                    <small>{window.t('course.draft_description_for_apple_user')}</small>
                                  </div>
                                  :
                                  <div className="mb-3">
                                    <small>{window.t('course.draft_description')}</small>
                                  </div>
                                }

                                <div className="form-check form-switch">
                                  <Field type="radio" name="status" value="for_free" className="form-check-input" id="statusForFree"
                                    onClick={() => {
                                      this.hideForSaleElement();
                                      this.handleStatusChange('for_free');
                                    }}
                                  />
                                  <label className="form-check-label" htmlFor="statusForFree">{window.t('course.for_free')}</label>
                                </div>
                                {course.apple_user ?
                                  <div className="mb-3">
                                    <small>{window.t('course.for_free_description_for_apple_user')}</small>
                                  </div>
                                  :
                                  <div className="mb-3">
                                    <small>{window.t('course.for_free_description')}</small>
                                  </div>
                                }

                                {course.apple_user ?
                                  <div className="form-check form-switch">
                                    <Field type="radio" name="status" value="for_sale" className="form-check-input" id="statusForSale" />
                                    <label className="form-check-label" htmlFor="statusForSale">{window.t('course.for_sale_for_apple_user')}</label>
                                  </div>
                                  :
                                  <div className="form-check form-switch">
                                    <Field type="radio" name="status" value="for_sale" className="form-check-input" id="statusForSale"
                                      onClick={() => {
                                        this.showForSaleElement();
                                        this.handleStatusChange('for_sale');
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="statusForSale">{window.t('course.for_sale')}</label>
                                  </div>
                                }
                                {course.apple_user ?
                                  <div className="mb-3">
                                    <small>{window.t('course.for_sale_description_for_apple_user')}</small>
                                  </div>
                                  :
                                  <div className="mb-3">
                                    <small>{window.t('course.for_sale_description')}</small>
                                  </div>
                                }

                                {!course.apple_user && this.state.status == 'for_sale' &&
                                  <Price course={course} onUpdateCourse={this.updateCourse} />
                                }

                                {course.apple_user ?
                                  <div className="form-check form-switch">

                                  </div>
                                  :
                                  <>
                                    {/* <div className="form-check form-switch mt-3">
                                      <Field type="checkbox" name="subscriptions" value="subscriptions" className="form-check-input" id="statusSubscriptions" />
                                      <label className="form-check-label" htmlFor="statusSubscriptions">{window.t('course.subscriptions')}</label>
                                    </div> */}
                                  </>

                                }

                                {course.apple_user ?
                                  <>
                                    <div className="form-check form-switch" hidden>
                                      <Field type="radio" name="status" value="temporary_block" className="form-check-input" id="statusTemporaryBlock" />
                                      <label className="form-check-label" htmlFor="statusTemporaryBlock">{window.t('course.temporary_block')}</label>
                                    </div>
                                    <div className='row' hidden>
                                      <small className="form-check-label" htmlFor="statusTemporaryBlock">{window.t('course.temporary_block_message_for_apple_user')}</small>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <div className="form-check form-switch">
                                      <Field type="radio" name="status" value="temporary_block" className="form-check-input" id="statusTemporaryBlock"
                                        onClick={() => {
                                          this.hideForSaleElement();
                                          this.handleStatusChange('temporary_block');
                                        }}
                                      />
                                      <label className="form-check-label" htmlFor="statusTemporaryBlock">{window.t('course.temporary_block')}</label>
                                    </div>
                                    <div className='row'>
                                      <small className="form-check-label" htmlFor="statusTemporaryBlock">{window.t('course.temporary_block_message')}</small>
                                    </div>
                                  </>
                                }

                                {values.status === 'temporary_block' &&
                                  <div className="my-2">
                                    <textarea type="text" name="message"
                                      className={"form-control mt-2 " + (errors.title && touched.title ? 'is-invalid' : '')}
                                      onChange={handleChange}
                                      value={values.message || ""} />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 text-end">
                              <button type="submit" className="btn btn-primary mt-3" disabled={isSubmitting || !isValid}>
                                {window.t('save')}
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Publication
